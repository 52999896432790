/** @type {import('tailwindcss').Config} */
export default {
    darkMode: 'selector',
    content: [
        "./{pages,partials,layouts}/**/*.htm",
        "../../plugins/**/components/**/*.htm"
    ],
    safelist: [
        'text-red-500',
        'text-green-500',
        'text-orange-500',
        'text-slate',
        'text-black',
        'text-white',
        'bg-red-500/10',
        'bg-green-500/10',
        'bg-orange-500/10',
        'bg-slate/10',
        'bg-blue/10',
        'bg-black/10',
        'bg-white/10'
    ],
    theme: {
        container: {
            center: true,
            padding: {
                DEFAULT: '2rem',
              }
        },
        extend: {
            fontFamily: {
                'sans': '"montserrat", sans-serif',
            },
            colors: {
                'blue': {
                    'DEFAULT' : '#7ba9da',
                    '50': '#f2f6fc',
                    '100': '#e2ecf7',
                    '200': '#ccddf1',
                    '300': '#a8c8e8',
                    '400': '#7ba9da',
                    '500': '#618fd0',
                    '600': '#4d76c3',
                    '700': '#4364b2',
                    '800': '#3b5292',
                    '900': '#344674',
                    '950': '#232d48',
                },
                'slate' : {
                    'DEFAULT' : '#38384c',
                    '50': '#f5f6f9',
                    '100': '#e8eaf1',
                    '200': '#d6dae7',
                    '300': '#bac1d6',
                    '400': '#99a2c1',
                    '500': '#8088b1',
                    '600': '#6e72a2',
                    '700': '#626493',
                    '800': '#53537a',
                    '900': '#464762',
                    '950': '#38384c',
                },
                'rose': {
                    'DEFAULT' : '#e05773',
                    '50': '#fdf3f3',
                    '100': '#fce7ea',
                    '200': '#f8d3d8',
                    '300': '#f3aeb9',
                    '400': '#eb8194',
                    '500': '#e05773',
                    '600': '#cb335a',
                    '700': '#aa264a',
                    '800': '#8f2244',
                    '900': '#7b203f',
                    '950': '#440d1f',
                },
                'black' : '#272735',
                'ess-orange' : '#FCB040'
            },
        },
    },
    plugins: [],
}